import { RoutingResoluton } from "@supernovaio/cloud/hooks/routing/useRoutingResolution"

import { getClientSdk } from "../data/getClientSdk"

import { getBrandRouteWithDestination } from "./getBrandRoute"
import { getLockedAccessRoute } from "./getLockedAccessRoute"
import { getNoDesignSystemRoute } from "./getNoDesignSystemRoute"
import { getNoWorkspacesRoute } from "./getNoWorkspacesRoute"
import { getOnboardingRoute } from "./getOnboardingRoute"
import { getPartialBaseRoute } from "./getPartialBaseRoute"
import {
  getCreateProfileRoute,
  getCreateWorkspaceRoute,
} from "./getWelcomeRoute"
import { BrandRouteDestination } from "./types"

type Props = {
  resolution: RoutingResoluton
  hasPermissionForDocs: boolean | undefined
  isNewOnboardingEnabled: boolean | undefined
  hasPermissionForDesignSystemOverview: boolean | undefined
}

export async function getFullBaseRoute({
  resolution,
  hasPermissionForDocs,
  isNewOnboardingEnabled,
  hasPermissionForDesignSystemOverview,
}: Props) {
  if (
    !resolution ||
    hasPermissionForDocs === undefined ||
    isNewOnboardingEnabled === undefined
  ) {
    return undefined
  }

  const sdk = await getClientSdk()

  // New onboarding
  if (isNewOnboardingEnabled && !resolution.isOnboardingFinished) {
    return getOnboardingRoute()
  }

  // Old onboarding
  if (!resolution.isOnboardingFinished) {
    if (!resolution.wsId) {
      return getCreateWorkspaceRoute()
    }

    return getCreateProfileRoute(sdk, resolution.wsId)
  }

  if (!resolution.wsId) {
    return getNoWorkspacesRoute()
  }

  if (resolution.isIpLocked) {
    return getLockedAccessRoute(resolution.wsId)
  }

  if (!resolution.dsId) {
    return getNoDesignSystemRoute(sdk, resolution.wsId)
  }

  if (!resolution.versionId || !resolution.brandId) {
    return getPartialBaseRoute(sdk, resolution.wsId, resolution.dsId)
  }

  // Unknown destination type is added for backward compatibility, where users doesnt have a preferred default destination.
  // These users are the ones that gone through old onboarding, where they were not able to choose the default destination.
  const hasUnkownDestination =
    resolution.preferredDefaultDestination === "unknownDestination"

  let destination

  if (hasPermissionForDesignSystemOverview) {
    destination = BrandRouteDestination.overview
  } else if (
    (hasUnkownDestination ||
      resolution.preferredDefaultDestination === "docs") &&
    hasPermissionForDocs
  ) {
    destination = BrandRouteDestination.documentation
  } else {
    destination = BrandRouteDestination.tokens
  }

  const route = await getBrandRouteWithDestination(
    resolution.wsId,
    resolution.dsId,
    resolution.versionId,
    resolution.brandId,
    destination
  )

  return `${route}`
}
